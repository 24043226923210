<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <form @submit.prevent="submit" class="form-horizontal">
          <div class="form-body">
            <h3 class="box-title">Pengumuman</h3>
            <hr class="m-t-0 m-b-40">

            

            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label :class="['control-label', {'text-right': !isMobile}, 'col-md-3']">
                    Status Pengumuman
                  </label>
                  <div class="col-md-8">
                    <treeselect 
                    v-model="payload.status" 
                    :options="statuspengumuman" 
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 m-t-10 m-b-10">
                <label :class="['control-label']">Pengumuman</label>
                <wysiwyg v-model="myHTML"/>
              </div>
            </div>

          </div>
          <hr>
          <div class="form-actions">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-offset-3 col-md-9">
                    <button type="submit" class="btn btn-success" title="Submit">Submit</button>&nbsp;
                    <button type="button" @click="goBack()" class="btn btn-inverse" title="Cancel">Cancel</button>
                  </div>
                </div>
              </div>
              <div class="col-md-6"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Multiselect from "vue-multiselect";
import Input from "@/components/input/input";
import axios from "axios";

export default {
  components: {
    Input,
    RotateSquare5,
    Multiselect,
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.information;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    isinvalidTipe() {
      return this.isTouched && this.payload.valueTipe.length === 0;
    }
  },
  data() {
    return {
      value: null,
      payload: {
        status: [],
      },
      myHTML: "",
      statuspengumuman: [],
      isDisabled: false,
      optionTipe: [],
    };
  },
  async mounted() {
    this.initialize();
    this.getAllReference();
    this.treeKlasifikasi();
    this.treeStatusPengumuman();
    // this.tipeFind("");
  },

  methods: {
    treeKlasifikasi(){
      axios.get(`/clasificationdoc/get_list_klasifikasi`).then(res=>{
        this.indukklasifikasi = res.data.data;
      })
    },
    treeStatusPengumuman(){
      this.statuspengumuman = [{"id": 1,"label": "Active"},{"id": 0,"label": "Inactive"}]
    },
    async initialize() {
      try {
        if (this.editMode) {
          axios
            .get(`/information/${this.$route.params.id}`)
            .then(response => {
              const state = {
                loaded: true
              };
              this.$store.commit("information/STATE", state);
              this.edit(response.data.data);
            });
        } else {
          this.$store.dispatch("information/onAdd");
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    getAllReference(){
      var query = "";
    },
    edit(data) {
      this.payload = {
        status: data.status,
        myHTML: data.pengumuman,
      };
      this.myHTML = data.pengumuman;
    },
    goBack() {
      this.$store.dispatch("information/onCancel");
    },
    onTouchKlasifikasi() {
      this.dropdownKlasifikasi.isTouched = true;
    },
    onTouchStatusRetensi() {
      this.dropdownStatusRetensi.isTouched = true;
    },
    onTouch() {
      this.isTouched = true;
      this.isTouchedInduk = true;
      this.isTouchedTujuan = true;
    },
    onChangeKlasifikasi(value) {
      this.dropdownKlasifikasi.value = value;
    },
    onChangeStatusRetensi(value) {
      this.dropdownStatusRetensi.value = value;
    },
    onChangeTipe(value) {
      this.payload.valueTipe = value;
    },
    submit() {
      const payload = {
        id: this.$route.params.id,
        status: parseInt(this.payload.status),
        pengumuman: this.myHTML,
      };
      // const data = JSON.stringify(this.payload);
      const data = payload;
      this.$validator.validateAll().then(success => {
        if (success) {
          const state = {
            loaded: false
          };
          if (this.editMode) {
            // this.$store.dispatch("information/submitEdit", {
            //   data,
            //   id: this.$route.params.id
            // });
            this.$store.dispatch("information/submitEdit", data);
          } else {
            this.$store.dispatch("information/submitAdd", data);
          }
        }
      });
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
.normal-background {
  background-color: #fff !important;
}
</style>

